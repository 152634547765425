import Title from '~/src/components/mui-wrappers/Title'
import { Box, BoxProps } from '@mui/material'

interface Props extends BoxProps<'div'>{
  title: string,
  subTitle: string,
}

const FormTitle = ({ title, subTitle, ...boxProps }: Props) => (
  <Box {...boxProps}>
    <Title variant='h5'>{title}</Title>
    <Title variant='h6'>
      {subTitle}
    </Title>
  </Box>
)

export default FormTitle

